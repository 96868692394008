import styled from "@emotion/styled";
import bannerContainer from 'images/banners/containers.jpeg';
import bannerProdutos from 'images/banners/produtos.jpg';
import bannerEmpresa from 'images/banners/empresa.jpg';
import entrega from 'images/entrega.png';
import parafuso from 'images/parafuso.png';
import entregaMobile from 'images/entrega-mobile.png';
import caixasMobile from 'images/caixas-mobile.png';
import parafusoMobile from 'images/parafuso-mobile.png';

export const Banners = styled.div`
  width: 100%;
  margin-top: -10px;
`

export const Banner = styled.div`
  height: 750px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  overflow: hidden;
  position: relative;
  background-size: cover;

  @media (max-width: 780px) {
    .text {
      font-size: 60px;
    }
  }

  @media (max-width: 590px) {
    height: 500px;

    .text {
      height: 500px;
      font-size: 50px;
      -webkit-text-stroke: 2px #004aad;
    }
  }

  @media (max-width: 480px) {
    height: 400px;

    .text {
      height: 400px;
      font-size: 40px;
    }
  }

  @media (max-width: 400px) {
    height: 400px;

    .text {
      height: 400px;
      font-size: 35px;
    }
  }
`

export const Banner1 = styled(Banner)`
  background-image: url(${bannerContainer});
`

export const Banner2 = styled(Banner)`
  background-image: url(${bannerProdutos});

  .text {
    margin-top: -120px;
  }

  @media (max-width: 780px) {
    .text {
      margin-top: -30px;
    }
  }

  @media (max-width: 400px) {
    .text {
      font-size: 30px;
    }
  }
`

export const Banner3 = styled(Banner)`
  background-image: url(${bannerEmpresa});

  .text {
    margin-top: -160px;
  }

  @media (max-width: 780px) {
    .text {
      margin-top: -140px;
    }
  }

  @media (max-width: 500px) {
    .text {
      margin-top: -100px;
      font-size: 30px;
    }
  }
`

export const Text = styled.div`
  width: 100%;
  height: 650px;
  position: absolute;
  text-transform: uppercase;
  display: grid;
  place-items: center;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  -webkit-text-stroke: 3px #004aad;
  -webkit-text-fill-color: white;
  z-index: 10;
  margin-top: -40px;

  span {
    width: 60%;;
    margin: 0 auto;
  }
`

export const Box = styled.div`
  margin: 30px auto;
  width: 1260px;
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  overflow: hidden;

  h2 {
    font-size: 36px;
  }

  @media (min-width: 901px) {
    height: 460px;
  }

  @media (max-width: 1259px) {
    width: 95%;
  }

  @media (max-width: 480px) {
    margin: 10px auto;
  }
`

export const BoxContent = styled.div`
  height: 100%;
  -webkit-text-stroke: 1px #004aad;

  @media (min-width: 901px) {
    padding: 30px 50px;
  }
`

export const BoxFrete = styled(BoxContent)`
  @media (min-width: 901px) {
    background-image: url(${entrega});
    background-position: top center;
    display: flex;
    justify-content: space-between;

    .text1 {
      width: 330px;
    }

    .text2 {
      width: 270px;
    }
  }
`

export const BoxFretePreco = styled.div`
  @media (max-width: 900px) {
    background-image: url(${entregaMobile});
    background-position: center;
    background-size: cover;
    min-height: 300px;

    .text {
      width: 280px;
      padding: 20px 30px;

      h2 {
        padding: 0;
      }
    }
  }

  @media (max-width: 480px) {
    .text {
      width: 280px;
      padding: 5px 30px;

      h2 {
        font-size: 24px;
      }
    }
  }
`

export const BoxFretePagamento = styled.div`
  @media (max-width: 900px) {
    background-image: url(${caixasMobile});
    background-position: center;
    background-size: cover;
    margin-top: 30px;
    display: flex;
    justify-content: right;

    .text {
      width: 360px;
      padding: 20px 30px 40px;

      h2 {
        padding: 0;
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 10px;

    .text {
      width: 240px;
      padding: 5px 30px 20px;

      h2 {
        font-size: 24px;
      }
    }
  }
`

export const BoxProduto = styled(BoxContent)`
  background-image: url(${parafuso});
  background-position: top center;
  font-size: 30px;

  h2 {
    font-size: 48px;
    color: #004aad;
  }

  .text1 {
    width: 560px;
  }

  @media (max-width: 900px) {
    background-image: url(${parafusoMobile});
    background-position: right center;
    background-size: cover;
    margin-top: 0;

    h2 {
      font-size: 36px;
      color: #004aad;
      margin: 0;
      padding-bottom: 30px;
    }

    .text1 {
      padding: 30px;
      width: 80%;
    }
  }

  @media (max-width: 590px) {
    .text1 {
      padding: 30px;
      width: 90%;
    }
  }

  @media (max-width: 480px) {
    font-size: 24px;

    h2 {
      font-size: 24px;
    }
  }
`
