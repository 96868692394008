import styled from "@emotion/styled";
import { MdArrowForwardIos, MdOutlineArrowBackIosNew } from 'react-icons/md'

export const NextSlide = styled(MdArrowForwardIos)`
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  padding: 30px;

  @media (max-width: 411px) {
    display: none;
  }
`

export const BackSlide = styled(MdOutlineArrowBackIosNew)`
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  padding: 30px;

  @media (max-width: 411px) {
    display: none;
  }
`
