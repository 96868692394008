import React from 'react';
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel';
import {
  NextSlide,
  BackSlide,
} from './styles'

const Slider = ({ children, slidesQtt }) => {
  const settings = {
    autoplay: slidesQtt > 1,
    autoplayInterval: 4000,
    pauseOnHover: false,
    wrapAround: true,
    initialSlideHeight: 750,
    transitionMode: 'scroll3d'
  }

  return (
    <Carousel
      {...settings}
      renderCenterLeftControls={({ previousSlide }) => (
        <BackSlide onClick={previousSlide} />
      )}

      renderCenterRightControls={({ nextSlide }) => (
        <NextSlide onClick={nextSlide} />
      )}
    >
      {children}
    </Carousel>
  )
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  slidesQtt: PropTypes.number.isRequired,
}

export default Slider
