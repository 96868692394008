import React from 'react';
import {Helmet} from 'react-helmet';
import { Layout } from 'components/Layout';
import { Top } from 'components/Top';
import { Bottom } from 'components/Bottom';
import { Slider } from 'components/Slider';
import {
  Banners,
  Text,
  Banner1,
  Banner2,
  Banner3,
  Box,
  BoxFrete,
  BoxProduto,
  BoxFretePreco,
  BoxFretePagamento,
} from './styles'

const Home = () => {
  return (
    <Layout>
      <Helmet title="Lucky Grobo" />
      <Top />
      <Banners>
        <Slider slidesQtt={3}>
          <Banner1>
            <Text className="text">
              <span>
                Produtos importados de primeira qualidade
              </span>
            </Text>
          </Banner1>
          <Banner2>
            <Text className="text">
              <span>
                Qualidade superior e maior durabilidade
              </span>
            </Text>
          </Banner2>
          <Banner3>
            <Text className="text">
              <span>
                Produtos de fixação
              </span>
            </Text>
          </Banner3>
        </Slider>
      </Banners>

      <Box>
        <BoxFrete>
          <BoxFretePreco className="text1">
            <div className="text">
              <h2>CONDIÇÕES ESPECIAIS DE ENTREGA</h2>
              Entre em contato conosco e saiba mais!!
            </div>
          </BoxFretePreco>
          <BoxFretePagamento className="text2">
            <div className="text">
              <h2>OFERECEMOS PAGAMENTO FACILITADO</h2>
              Preços e condições especias, aproveite e faça uma cotação conosco!!!
            </div>
          </BoxFretePagamento>
        </BoxFrete>
      </Box>

      <Box>
        <BoxProduto>
          <div className="text1">
            <h2>PRODUTOS DE PRIMEIRA QUALIDADE</h2>
            Importados de fornecedores que utilizam métodos e materias
            para garantir a qualidade e durabilidade
          </div>
        </BoxProduto>
      </Box>

      <Bottom />
    </Layout>
  )
}

export default Home;
